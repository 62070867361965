import { useState, useEffect } from "react";
/**
 *
 * @returns {boolean} Is navigator online
 */
function getIsOnline() {
    if (typeof window === "undefined") {
        return null;
    }
    return navigator.onLine;
}
/**
 * useOnline hook
 *
 * Returns true if navigator is online, false if not.
 *
 * @returns {boolean} The value of navigator.onLine
 */
function useOnline() {
    var _a = useState(function () { return getIsOnline(); }), online = _a[0], changeOnline = _a[1];
    function setOffline() {
        changeOnline(false);
    }
    function setOnline() {
        changeOnline(true);
    }
    // we only needs this to be set on mount
    // hence []
    useEffect(function () {
        if (typeof window !== "undefined") {
            window.addEventListener("online", setOnline);
            window.addEventListener("offline", setOffline);
            return function () {
                window.removeEventListener("online", setOnline);
                window.removeEventListener("offline", setOffline);
            };
        }
        else {
            console.warn("useOnline: window is undefined.");
        }
    }, []);
    return online;
}
export { useOnline };
